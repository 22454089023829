*{
  margin:0;
  padding:0;
  box-sizing: border-box
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "LeagueBold";
  src: local("LeagueBold"),
      url("./webfonts/LeagueSpartan-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
font-family: "LeagueMedium";
src: local("LeagueMedium"),
    url("./webfonts/LeagueSpartan-Medium.ttf") format("truetype");
font-weight: bold;
}
@font-face {
font-family: "LeagueRegular";
src: local("LeagueRegular"),
    url("./webfonts/LeagueSpartan-Regular.ttf") format("truetype");
font-weight: bold;
}
@font-face {
font-family: "LeagueBlack";
src: local("LeagueBlack"),
    url("./webfonts/LeagueSpartan-Black.ttf") format("truetype");
font-weight: bold;
}
@font-face {
  font-family: "LeagueSemibold";
  src: local("LeagueSemibold"),
      url("./webfonts/LeagueSpartan-SemiBold.ttf") format("truetype");
  font-weight: bold;
  }
  @font-face {
    font-family: "RocaBold";
    src: local("RocaBold"),
        url("./webfonts/Roca\ Bold.ttf") format("truetype");
    font-weight: bold;
  }
  
  @font-face {
  font-family: "RocaRegular";
  src: local("RocaRegular"),
      url("./webfonts/Roca\ Regular.ttf") format("truetype");
  font-weight: bold;
  }
  @font-face {
  font-family: "RocaBlack";
  src: local("RocaBlack"),
      url("./webfonts/Roca\ Black.ttf") format("truetype");
  font-weight: bold;
  }
  @font-face {
    font-family: "LeagueSemibold";
    src: local("LeagueSemibold"),
        url("./webfonts/LeagueSpartan-SemiBold.ttf") format("truetype");
    font-weight: bold;
    }

